import { api } from './api';
import { formatObjectToQueryString } from '../../utils/format-object-to-query-string';
import { ThunkResult } from '@reduxjs/toolkit/dist/query/core/buildThunks';

const BASE_URL = process.env.REACT_APP_BACKEND_API2_PATH as string;

interface IdDateQuery {
  lineID: number;
  contentDate: string;
}

interface IdDateQueryWithType extends IdDateQuery {
  type: string;
}

export const monitoringApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.mutation({
      query() {
        return {
          url: '/api-ext/user/api/companies',
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getProject: builder.mutation({
      query(id: number) {
        return {
          url: `/api-ext/user/api/projects/${id}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getContentTags: builder.mutation<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        return {
          url: `/api-ext/user/api/lines/${lineID as number}/?date=${
            contentDate as string
          }`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getPanorama: builder.mutation<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        return {
          url: `/api-ext/user/api/lines/${lineID as number}/content/panorama?dateFrom=${
            contentDate as string
          }`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getLineScheme: builder.query<any, any>({
      query({ lineID, contentType, contentDate }: any): any {
        return {
          url: `/api-ext/user/api/lineScheme/?${formatObjectToQueryString({
            lineId: lineID,
            contentType,
            date: contentDate,
          })}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getCalendarByType: builder.query<IdDateQuery, any>({
      query({ lineID, contentType }: { lineID: number; contentType: string }): any {
        return {
          url: `/api-ext/user/api/lines/${lineID}/content/${contentType.toLowerCase()}/calendarByType`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getLineContentTabs: builder.query<IdDateQuery, any>({
      query({ lineID, contentDate }: any): any {
        const contentDateParam = contentDate ? `date=${contentDate as number}` : '';

        return {
          url: `/api-ext/user/api/lines/${
            lineID as number
          }/contentsTabs?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
    }),
    getAllLinesByProject: builder.mutation<IdDateQueryWithType, ThunkResult>({
      query({ projectID }: any): any {
        return {
          url: `/api-ext/user/api/projects/${projectID as number}/lines/`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getLineContentByType: builder.mutation<IdDateQueryWithType, ThunkResult>({
      query({ lineID, type, contentDate }: IdDateQueryWithType) {
        const contentDateParam = contentDate ? `date=${contentDate}` : '';

        return {
          url: `/api-ext/user/api/lines/${lineID}/content/${type.toLowerCase()}?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
    getLineContent: builder.mutation<IdDateQuery, ThunkResult>({
      query({ lineID, contentDate }: IdDateQuery) {
        const contentDateParam = contentDate ? `date=${contentDate}` : '';

        return {
          url: `/api-ext/user/api/lines/${lineID}/content/lineContents?${contentDateParam}`,
          method: 'GET',
          credentials: 'include',
        };
      },
      invalidatesTags: ['Companies'],
    }),
  }),
});

export const {
  useGetCompaniesMutation,
  useGetLineSchemeQuery,
  useGetPanoramaMutation,
  useGetLineContentMutation,
  useGetLineContentTabsQuery,
  useGetLineContentByTypeMutation,
  useGetCalendarByTypeQuery,
  useGetProjectMutation,
} = monitoringApi;

// https://media-dev.interactivemap.ru/api-ext/user/api/lineScheme/?lineId=1&contentType=PANORAMA&date=2022-08-29&
// https://media-dev.interactivemap.ru/api-ext/user/api/lines/1/content/lineContents?date=2022-08-29
