import { Search } from './search';
import { SearchWrapperProps } from './search.types';
import { useAppSelector, useFilteredObjectsSelector } from '../../state';
// import { items } from '../../___prototype';
import { ObjectInterface } from '../../types';

export const SearchWrapper = ({ children }: SearchWrapperProps) => {
  const items = useFilteredObjectsSelector();

  return <Search items={items} />;
};
