/* eslint-disable */

import { ObjectInfo } from '../../components/object-info';
import styles from './sidebar.module.scss';
import { ObjectInfoBox } from '../object-info-box';
import type { SidebarObjectInfoProps } from './sidebar.types';
import { PhotosSwitcher } from '../photos-switcher';
import { GalleryType, PhotoType } from '../photos-switcher/photos-switcher.types';
import { SidebarSelectEditField } from '../sidebar-select-edit-field/sidebar-select-edit-field';
import { macroRegions, programs, startDate, typeOfWork } from '../sidebar-filter/data';
import { getMappedGalleryObject } from '../../utils/get-mapped-gallery-object';
import { ObjectButtons } from '../object-buttons';
import { Button, SpanTag, ToolTip } from '../common';
import { SidebarObjectInfoHeading } from './sidebar-constants';
import React from 'react';
import {
  clearProject,
  filterObjects,
  setGalleryObjectId,
  setMonitoringOpen,
  useAppDispatch,
  useDeviceSelector,
} from '../../state';
import { useFlyToObjectOnMap } from '../../hooks';
import { getContentValues } from '../object-info/utils';
import { Heading, directions, realStatus, nonprofit } from '../../constants';
import { IDegreeOfSatisfaction } from '../../types';
import { useSelectObject } from '../../hooks/depended/useSelectObject';
import { batch } from 'react-redux';
import { setInfoTemplateString } from 'utils';

export const SidebarObjectInfo = ({
  object,
  onCloseClick,
  onImageClick,
  editorMode,
  onEditField,
  isObjectUpdating = false,
  onChangeObject,
  isMonitoringActive = false,
  handleOpenAnalytics,
  isAnalyticsViewOpen,
}: SidebarObjectInfoProps): JSX.Element => {
  const mediaObject = getMappedGalleryObject(object?.media, object?.name, object?.id);
  const mediaGratitude = getMappedGalleryObject(
    object?.gratitude,
    object?.name,
    object?.id,
  );
  const handleImageClick = (item: PhotoType, galleryType?: GalleryType) =>
    onImageClick(item, galleryType);
  const contentValues = getContentValues(object!);
  const { isMobile } = useDeviceSelector();
  const flyTo = useFlyToObjectOnMap();
  const dispatch = useAppDispatch();
  const [, setSelectedObjectId] = useSelectObject();

  const flyToObject = () =>
    flyTo([object.coords.coordinates[0], object.coords.coordinates[1]]);

  const showAdditionalCharityInfo =
    object &&
    (object.realization_status ||
      object.direction ||
      object.description_of_charitable_works);

  const submitFiltersHandler = (
    selectedNationalProjects: number[],
    selectedDirections: number[],
  ) => {
    setSelectedObjectId(null);
    batch(() => {
      dispatch(setGalleryObjectId(null));
      dispatch(clearProject());
      dispatch(setMonitoringOpen(false));
      dispatch(
        filterObjects({
          macro_region: [],
          program: [],
          start_of_building: [],
          type_of_work: [],
          status: [],
          showOnlyFavorite: false,
          national_projects: selectedNationalProjects,
          directions: selectedDirections,
          degree_of_satisfaction: { min: 0, max: 10 },
          gazprom: [],
        }),
      );
    });
  };

  return (
    <>
      {showAdditionalCharityInfo && (
        <div className={styles['info-container']}>
          <div className={styles['bg-element']}>
            <SpanTag className={styles['description__charity_title']} weight={1}>
              {SidebarObjectInfoHeading.descriptionOfCharitableWorks}
            </SpanTag>
            <div className={styles['description__charity']}>
              <div className={styles['description__charity_body']}>
                {contentValues.nationalProject ? (
                  <ToolTip
                    isVisible={
                      Number(contentValues?.nationalProject?.length) +
                        Number(Heading?.nationalProject?.length) >
                      100
                    }
                    content={contentValues.nationalProject}
                  >
                    <div className={styles['description__charity_body-item']}>
                      <SpanTag
                        weight={2}
                        className={styles['description__charity_body-item_name']}
                      >
                        {Heading.nationalProject}
                      </SpanTag>
                      <SpanTag
                        onClick={() => submitFiltersHandler([Number(object.program)], [])}
                        className={styles['description__charity_body-item_descr_a']}
                      >
                        {contentValues.nationalProject}
                      </SpanTag>
                    </div>
                  </ToolTip>
                ) : null}

                {contentValues.direction ? (
                  <ToolTip
                    isVisible={
                      Number(contentValues.direction.length) +
                        Number(Heading?.direction?.length) >
                      43
                    }
                    content={contentValues.direction}
                  >
                    <div className={styles['description__charity_body-item']}>
                      <SpanTag
                        weight={2}
                        className={styles['description__charity_body-item_name']}
                      >
                        {SidebarObjectInfoHeading.direction}
                      </SpanTag>
                      <SpanTag
                        onClick={() =>
                          submitFiltersHandler([], [Number(object.direction_id)])
                        }
                        className={styles['description__charity_body-item_descr_a']}
                      >
                        {contentValues.direction.length > 30
                          ? `${contentValues.direction.slice(0, 30)}...`
                          : contentValues.direction}
                      </SpanTag>
                    </div>
                  </ToolTip>
                ) : null}

                {contentValues.realizationStatus ? (
                  <div className={styles['description__charity_body-item']}>
                    <SpanTag
                      weight={2}
                      className={styles['description__charity_body-item_name']}
                    >
                      {SidebarObjectInfoHeading.realizationStatus}
                    </SpanTag>
                    <SpanTag className={styles['description__charity_body-item_descr']}>
                      {contentValues.realizationStatus}
                    </SpanTag>
                  </div>
                ) : null}
                {/* <SpanTag
              className={styles['description__charity_body-item_p']}>{object.description_of_charitable_works}</SpanTag> */}

                {contentValues.owner ? (
                  <ToolTip
                    isVisible={
                      Number(contentValues.owner.length) + Number(Heading.owner.length) >
                      100
                    }
                    content={contentValues.owner}
                  >
                    <div className={styles['description__charity_body-item']}>
                      <SpanTag
                        weight={2}
                        className={styles['description__charity_body-item_name']}
                      >
                        {Heading.owner}
                      </SpanTag>
                      <SpanTag className={styles['description__charity_body-item_descr']}>
                        {contentValues.owner}
                      </SpanTag>
                    </div>
                  </ToolTip>
                ) : null}

                {/* </div> */}
                {/* <div className={styles['description__charity_body']}> */}
              </div>
            </div>
          </div>

          <div className={styles['bg-element']}>
            <div className={styles['description__charity']}>
              <div className={styles['description__charity_body']}>
                {contentValues.place ? (
                  <ToolTip
                    isVisible={
                      Number(contentValues.place.length) + Number(Heading.place.length) >
                      100
                    }
                    content={contentValues.place}
                  >
                    <div className={styles['description__charity_body-item']}>
                      <SpanTag
                        weight={2}
                        className={styles['description__charity_body-item_name']}
                      >
                        {Heading.place}
                      </SpanTag>
                      <SpanTag className={styles['description__charity_body-item_descr']}>
                        {contentValues.place}
                      </SpanTag>
                    </div>
                  </ToolTip>
                ) : null}
                {contentValues.necessaryInvest ? (
                  <ToolTip
                    isVisible={
                      Number(contentValues.necessaryInvest.length) +
                        Number(Heading.necessaryInvest.length) >
                      100
                    }
                    content={contentValues.necessaryInvest}
                  >
                    <div className={styles['description__charity_body-item']}>
                      <SpanTag
                        weight={2}
                        className={styles['description__charity_body-item_name']}
                      >
                        {Heading.necessaryInvest}
                      </SpanTag>
                      <SpanTag className={styles['description__charity_body-item_descr']}>
                        {contentValues.necessaryInvest}
                      </SpanTag>
                    </div>
                  </ToolTip>
                ) : null}
                {contentValues.rating ? (
                  <ToolTip
                    isVisible={
                      Number(contentValues.rating.length) +
                        Number(Heading.rating.length) >
                      100
                    }
                    content={contentValues.rating}
                  >
                    <div className={styles['description__charity_body-item']}>
                      <SpanTag
                        weight={2}
                        className={styles['description__charity_body-item_name']}
                      >
                        {Heading.rating}
                      </SpanTag>
                      <SpanTag className={styles['description__charity_body-item_descr']}>
                        {contentValues.rating}
                      </SpanTag>
                    </div>
                  </ToolTip>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        {/*<div className={styles['buttons-container']}>*/}
        {/*  <ObjectButtons*/}
        {/*    object={object}*/}
        {/*    editorMode={editorMode}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className={styles['buttons-container']}>
          <Button
            className={styles['object-info-buttons-group-button-display']}
            elementType={'button'}
            typeBtn={'secondary'}
            children={!isMobile && 'Показать на карте'}
            size={'s'}
            onlyIcon={isMobile}
            showLeftIcon
            leftIcon={<i className={'icon icon-map-pin icon-tiny'} />}
            onClick={flyToObject}
            style={{ gap: 4 }}
          />

          {/*<Button*/}
          {/*  className={styles['object-info-button']}*/}
          {/*  elementType={'button'}*/}
          {/*  style={{ marginTop: 8 }}*/}
          {/*  typeBtn={'secondary'}*/}
          {/*  children={'Текущий статус работ'}*/}
          {/*  showLeftIcon*/}
          {/*  onClick={handleOpenAnalytics}*/}
          {/*  size={'s'}*/}
          {/*  leftIcon={*/}
          {/*    isAnalyticsViewOpen && <i className={'icon icon-close-3 icon-tiny'} />*/}
          {/*  }*/}
          {/*/>*/}
        </div>
      </div>
      <PhotosSwitcher
        photosSwitcherTitle={'Фотографии с объекта'}
        galleryType={'object'}
        onImageClick={handleImageClick}
        photoList={mediaObject}
        editorMode={editorMode}
      />
      <PhotosSwitcher
        photosSwitcherTitle={'Благодарственные письма'}
        onImageClick={handleImageClick}
        galleryType={'gratitude'}
        photoList={mediaGratitude}
        editorMode={editorMode}
      />
      <ObjectInfoBox
        editorMode={editorMode}
        title={SidebarObjectInfoHeading.scopeAndStagesOfWork}
        content={setInfoTemplateString(object?.scope_and_stages_of_work)}
        type="scopeAndStagesOfWork"
        onEditField={onEditField}
        isLoading={isObjectUpdating}
      />
      <ObjectInfoBox
        editorMode={editorMode}
        title={SidebarObjectInfoHeading.budgetAllocation}
        content={setInfoTemplateString(object?.budget_allocation)}
        type="budget_allocation"
        onEditField={onEditField}
        isLoading={isObjectUpdating}
      />

      <ObjectInfoBox
        editorMode={editorMode}
        title={SidebarObjectInfoHeading.descriptionObject}
        content={object?.description}
        type="description"
        onEditField={onEditField}
        isLoading={isObjectUpdating}
      />
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.programValue}
          isLoading={isObjectUpdating}
          content={object.program_id}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'program_id'}
          values={programs}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.startOfBuilding}
          isLoading={isObjectUpdating}
          content={object.start_of_building ? String(object.start_of_building) : '0'}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'start_of_building'}
          values={startDate}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.typeOfWork}
          isLoading={isObjectUpdating}
          content={object.type_of_work ? String(object.type_of_work) : '0'}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'type_of_work'}
          values={typeOfWork}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.monitoringId}
          isLoading={isObjectUpdating}
          content={object.monitoring_id}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'monitoring_project_id'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.macroRegionValue}
          isLoading={isObjectUpdating}
          content={object.macro_region}
          editorMode={editorMode}
          objectId={object.id}
          onChangeObject={onChangeObject}
          type={'macro_region'}
          values={macroRegions}
        />
      )}
      {editorMode && (
        <ObjectInfoBox
          editorMode={editorMode}
          title={SidebarObjectInfoHeading.realizationPeriod}
          content={object?.realization_period}
          type="realization_period"
          onEditField={onEditField}
          isLoading={isObjectUpdating}
        />
      )}
      <ObjectInfoBox
        editorMode={editorMode}
        title={SidebarObjectInfoHeading.volumeWorks}
        content={object?.needs}
        type="needs"
        onEditField={onEditField}
        isLoading={isObjectUpdating}
      />

      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.name}
          isLoading={isObjectUpdating}
          content={object.name}
          editorMode={editorMode}
          objectId={object?.id}
          onChangeObject={onChangeObject}
          type={'name'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.owner}
          isLoading={isObjectUpdating}
          content={object.owner}
          editorMode={editorMode}
          objectId={object?.id}
          onChangeObject={onChangeObject}
          type={'owner'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.possibility}
          isLoading={isObjectUpdating}
          content={object.common_popularity}
          editorMode={editorMode}
          objectId={object?.id}
          onChangeObject={onChangeObject}
          type={'common_popularity'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.employeeVisits}
          isLoading={isObjectUpdating}
          content={object.population}
          editorMode={editorMode}
          objectId={object?.id}
          onChangeObject={onChangeObject}
          type={'population'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.necessaryInvest}
          isLoading={isObjectUpdating}
          content={object.money_needs}
          editorMode={editorMode}
          objectId={object?.id}
          onChangeObject={onChangeObject}
          type={'money_needs'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.distanceDistrictCenter}
          isLoading={isObjectUpdating}
          content={object.distance}
          editorMode={editorMode}
          objectId={object?.id}
          onChangeObject={onChangeObject}
          type={'distance'}
          inputComponent={'input'}
        />
      )}
      {editorMode && (
        <SidebarSelectEditField
          title={SidebarObjectInfoHeading.place}
          isLoading={isObjectUpdating}
          content={object.place}
          editorMode={editorMode}
          objectId={object?.id}
          onChangeObject={onChangeObject}
          type={'place'}
          inputComponent={'input'}
        />
      )}

      <ObjectInfoBox
        editorMode={editorMode}
        title={SidebarObjectInfoHeading.dangerZone}
        type="delete_object"
        onEditField={onEditField}
        isLoading={isObjectUpdating}
        content={''}
        objectId={object?.id}
        objectTitle={object?.name}
        onCloseSidebar={onCloseClick}
      />
    </>
  );
};
