import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from '../common';
import { IconNavArrow } from '../images-gallery/nav-arrow-icon';
import { GalleryProps } from './image-gallery-wrapper.types';
import './image-gallery-wrapper.scss';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { ImageWrapper } from 'components/image-wrapper';
import thumb from 'images/object-bg-image.png';

export type Ref = ImageGallery;
export const ImageGalleryWrapper = ({
  galleryMedia,
  showThumbnails = true,
  showPlayButton,
  showFullscreenButton,
  lazyLoad,
  showBullets,
  useBrowserFullscreen,
  onSlide,
  onScreenChange,
  disableThumbnailScroll,
  fullScreenModeOnClick = false,
  renderThumbInner,
  galleryRef,
}: GalleryProps) => {
  const [indexListen, setIndexListen] = useState<number>(0);
  const [isFullScreenMode, setFullScreenMode] = useState<boolean>(false);
  const galleryRefObj = useRef<ImageGallery | null>(null);

  const handleFullScreenOnClick = () => {
    if (fullScreenModeOnClick) {
      setFullScreenMode(!isFullScreenMode);
      if (
        galleryRefObj !== null &&
        galleryRefObj !== undefined &&
        galleryRefObj.current
      ) {
        isFullScreenMode || galleryRefObj?.current?.fullScreen();
      }
      if (galleryRefObj !== null && galleryRefObj !== undefined) {
        isFullScreenMode && galleryRefObj?.current?.exitFullScreen();
      }
    }
  };
  // const handleKeyboardEvent = (e: KeyboardEvent<HTMLDivElement>) => {
  //   const { code } = e;
  //   //console.log(code);
  //   if (code === 'Escape') {
  //     isFullScreenMode && galleryRefObj?.current?.exitFullScreen();
  //   }
  // };

  const handleScrollOnFirsOrLastImage = (thumbnailsContainer: Element) => {
    thumbnailsContainer.scroll({
      left:
        indexListen === galleryMedia.length - 1
          ? thumbnailsContainer.scrollWidth
          : -thumbnailsContainer.scrollWidth,
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleScrollOnThumbnailClick = (thumbnailsContainer: Element, width: number) => {
    thumbnailsContainer.scroll({
      left: width * indexListen,
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const thumbnailsContainer = document.querySelector(
      '.image-gallery-thumbnails-container',
    );
    const thumbnailItemWidth =
      thumbnailsContainer &&
      thumbnailsContainer !== null &&
      thumbnailsContainer.children[indexListen].getBoundingClientRect().width;

    if (indexListen === galleryMedia.length - 1 || indexListen === 0) {
      thumbnailsContainer &&
        thumbnailsContainer !== null &&
        handleScrollOnFirsOrLastImage(thumbnailsContainer);
    }
    if (indexListen !== galleryMedia.length - 1 && indexListen !== 0) {
      thumbnailsContainer &&
        thumbnailsContainer !== null &&
        handleScrollOnThumbnailClick(thumbnailsContainer, Number(thumbnailItemWidth));
    }

    onSlide && onSlide(indexListen);
  }, [indexListen]);

  // useEffect(()=> {

  // }, [indexListen]);

  const itemIdx = galleryRefObj?.current?.getCurrentIndex();
  const img = (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        zIndex: 3100,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundColor: 'black',
      }}
    >
      <Button
        style={{
          position: 'absolute',
          cursor: 'pointer',
          right: '40px',
          top: '20px',
        }}
        size={'l'}
        elementType={'button'}
        onlyIcon={true}
        typeBtn={'elevation'}
        showLeftIcon={true}
        leftIcon={<i className="icon icon-close-3 icon-middle"></i>}
      />
      <ImageWrapper
        wrapperStyles={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        style={{ width: 'inherit', cursor: 'zoom-out' }}
        src={String(galleryMedia[Number(itemIdx)]?.original)}
        alt={galleryMedia[Number(itemIdx)]?.originalAlt}
        id={String(galleryMedia[Number(itemIdx)]?.id)}
      />
    </div>
  );

  const renderItem = (imgItem: any) => {
    if (isFullScreenMode) {
      return (
        ReactDOM &&
        ReactDOM?.createPortal(img, document.getElementById('root') as HTMLDivElement)
      );
    }

    return (
      <div className={'image-gallery'}>
        <ImageWrapper
          src={imgItem.original as string}
          wrapperStyles={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
          alt={imgItem.originalAlt as string}
          id={imgItem.id as string}
          style={{ height: 'auto' }}
          onLoad={(e: any) => {
            if (e.target.naturalHeight > e.target.naturalWidth) {
              e.target.className = 'vertical-image';
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className={'gallery-wrapped'}>
      <ImageGallery
        ref={(element) => {
          galleryRefObj.current = element;
          if (typeof galleryRef === 'function') {
            galleryRef(element);
          } else {
            if (galleryRef) {
              // @ts-ignore
              galleryRef.current = element;
            }
          }
        }}
        renderItem={renderItem}
        items={galleryMedia as ReactImageGalleryItem[]}
        onClick={handleFullScreenOnClick}
        showThumbnails={!isFullScreenMode && showThumbnails}
        showPlayButton={showPlayButton}
        showFullscreenButton={showFullscreenButton}
        lazyLoad={lazyLoad}
        showBullets={showBullets}
        useBrowserFullscreen={useBrowserFullscreen}
        onSlide={(idx) => setIndexListen(idx)}
        renderThumbInner={renderThumbInner}
        //disableThumbnailScroll={true}
        //onThumbnailClick={()=> console.log('ss')}
        renderLeftNav={(onClick, disabled) => (
          <div onClick={onClick} className={'image-gallery-custom-nav-wrapper-left'}>
            <Button
              className={'image-gallery-custom-nav-left'}
              onClick={onClick}
              disabled={disabled}
              elementType={'button'}
              typeBtn={'uncolored'}
              showLeftIcon={true}
              justifyContent={'end'}
              leftIcon={<IconNavArrow className={'arrow-icon-with-hover'} />}
            />
          </div>
        )}
        renderRightNav={(onClick, disabled) => (
          <div onClick={onClick} className={'image-gallery-custom-nav-wrapper-right'}>
            <Button
              className={'image-gallery-custom-nav-right'}
              elementType={'button'}
              typeBtn={'uncolored'}
              showRightIcon={true}
              justifyContent={'end'}
              onClick={onClick}
              disabled={disabled}
              rightIcon={<IconNavArrow className={'arrow-icon-with-hover'} />}
            />
          </div>
        )}
      />
    </div>
  );
};
