import { Media } from '../types';

export const getMappedGalleryObject = (
  media: Media[],
  name: string,
  objectId: number,
) => {
  return media && media.length > 0
    ? media.map(({ id, path, path_thumbnail }) => {
        return {
          image: !!path ? path : path_thumbnail,
          thumb: path_thumbnail ? path_thumbnail : null,
          alt: `${name} фотография`,
          id,
          object_id: objectId,
        };
      })
    : [];
};
