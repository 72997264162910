import { useAppSelector } from './selector';
import { ObjectType } from '../../types';
import { useEditorModeStateSelector, useFavoriteObjectsSelector } from './user-selectors';
import { commonFilterObject } from '../../utils/common-filter-object';
import { useSelectedObjectSelector } from '../../history-state/selectors';
import { filterObjects } from '../../utils';

export const useEnableObjectFiltersSelector = () => {
  return useAppSelector(({ objects }) => objects.filters);
};

export const useObjectsItemSelector = () =>
  useAppSelector(({ objects }) => objects.items);

export const useObjectsTypesSelector = (): ObjectType[] =>
  useAppSelector(({ objects }) => objects.types);

export const useObjectsProgramsSelector = () =>
  useAppSelector(({ objects }) => objects.programs);

export const useObjectMonitoringProjectIdSelector = (objectId: string | undefined) => {
  const { items } = useAppSelector(({ objects }) => objects);

  if (!objectId) return null;

  const object = items.find((el) => {
    return el.id === Number(objectId);
  });

  return object ? Number(object.monitoring_id) : null;
};

export const useObjectAnalyticsByObjectIdSelector = (objectId: string | undefined) => {
  const { items } = useAppSelector(({ objects }) => objects);

  if (!objectId) return null;

  const object = items.find((el) => {
    return el.id === Number(objectId);
  });

  return object ? object.analytics : null;
};

export const useObjectByProjectIdSelector = (projectID: string | undefined) => {
  const { items } = useAppSelector(({ objects }) => objects);

  if (!projectID) return null;

  const object = items.find((el) => {
    return Number(el.monitoring_id) === Number(projectID);
  });

  return object ? object : null;
};

export const useIsGazpromFilteredActive = () => {
  const { filters } = useAppSelector(({ objects }) => objects);

  return !!filters.gazprom.length;
};

export const useStateSelectedObject = () =>
  useAppSelector(({ objects }) => objects.selectedItem);

export const useFilteredObjectsSelector = () => {
  const { items, filters } = useAppSelector(({ objects }) => objects);

  let result = filterObjects(filters, items);

  if (filters.degree_of_satisfaction.min > 0) {
    result = result.filter((el: any) => {
      return el.rating >= filters.degree_of_satisfaction.min;
    });
  }
  if (filters.degree_of_satisfaction.max < 10) {
    result = result.filter((el: any) => {
      return el.rating <= filters.degree_of_satisfaction.max;
    });
  }
  if (filters.gazprom.length) {
    result = result.filter((el: any) => {
      return filters.gazprom.includes(Number(el.gazprom_filter));
    });
  }

  return result;
};

export const useDistrictFilteredObjects = () => {
  const { items, filters } = useAppSelector(({ objects }) => objects);

  return filterObjects(filters, items);
};

export const useObjectUpdateErrorSelector = () =>
  useAppSelector(({ objects }) => objects.errors.updateObject);
