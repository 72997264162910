import { memo } from 'react';
import styles from './object-card.module.scss';
import { ObjectCardProps } from './object-card.types';
import { Card, H4Tag, PTag, SpanTag, ToolTip } from '../common';
import { ObjectInfo } from '../object-info';
import { PhotosSwitcher } from '../photos-switcher';
import emptyPhotoListImage from '../../images/object-bg-image.png';
import { getMappedGalleryObject } from '../../utils/get-mapped-gallery-object';
import { ObjectButtons } from '../object-buttons';
import { ImageWrapper } from 'components/image-wrapper';

const MAX_TITLE_LENGTH = 31;

export const ObjectCard = ({
  selected = false,
  object,
  editorMode = false,
  role = 'user',
  onClick,
  onImageClick,
  onCloseSidebar,
}: ObjectCardProps) => {
  // const title =
  //   object.name.length <= MAX_TITLE_LENGTH
  //     ? object.name
  //     : `${object.name.slice(0, MAX_TITLE_LENGTH)}...`;

  const photoList = getMappedGalleryObject(object?.media, object.name, object.id);

  return (
    <Card selected={selected} onClick={onClick ? onClick : () => ''}>
      <div className={styles['card-header']}>
        <ToolTip
          className={styles['card-header-tooltip']}
          isVisible={Number(object.name.length) > 60}
          content={object.name}
        >
          <H4Tag className={styles['card-header-header']} weight={3}>
            {object.name}
          </H4Tag>
        </ToolTip>
        <ToolTip
          className={styles['card-header-tooltip']}
          isVisible={Number(object.description_of_charitable_works.length) > 82}
          content={object.description_of_charitable_works}
        >
          <SpanTag className={styles['card-header-subheader']}>
            {object.description_of_charitable_works}
          </SpanTag>
        </ToolTip>
      </div>
      {photoList.length ? (
        <ImageWrapper
          style={{ width: 'inherit', marginTop: '-15px' }}
          wrapperStyles={{ height: '150px', overflow: 'hidden' }}
          src={(photoList[0].thumb || photoList[0].image) as string}
          alt={String(photoList[0].alt)}
          thumb={emptyPhotoListImage}
        />
      ) : (
        <div
          className={styles['card-empty-photo-list']}
          style={{ backgroundImage: `url(${emptyPhotoListImage})` }}
        />
      )}
      <div className={styles['card-info']}>
        <div className={styles['card-container']}>
          <ObjectInfo
            type="card"
            onCloseSidebar={onCloseSidebar}
            object={object}
            role={role}
            editorMode={editorMode}
          />
        </div>
        {/* <div className={styles['element-container']}></div> */}
        <div className={styles['card-container']}>
          <ObjectButtons editorMode={editorMode} object={object} type={'card'} />
        </div>
      </div>
    </Card>
  );
};
