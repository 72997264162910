import React, { memo, Suspense, useEffect, useState } from 'react';
import Spinner from '../../components/common/spinner/spinner';
import styles from './media-page.module.scss';
import { SidebarZones } from 'components/sidebar-zones';
import {
  useAppSelector,
  useGetBaseObjectsMutation,
  useGetProjectMutation,
  useGetProjectSelector,
} from '../../state';
import { HeaderMediaWrapper } from 'components/header';
import { useParams } from 'react-router-dom';
import { MapLoader } from '../../components';

import { useBreakpointsComparison } from '../../hooks';
import { ScreenSize } from '../../constants';

const MediaView = React.lazy(() => import('../../components/view/media-view/media-view'));

export const MediaPage = (props) => {
  const [getObjects, { isObjectsLoading }] = useGetBaseObjectsMutation();
  const { objects, user, monitoring } = useAppSelector((state) => state);
  const [groupLines, setGroupLines] = useState([]);
  const { items, types } = objects;
  const isTabletLandscape = useBreakpointsComparison(ScreenSize.TL);

  useEffect(() => {
    !items.length && getObjects();
  }, []);

  const { projectID, groupID, contentType } = useParams();
  const [getProject, { isLoading }] = useGetProjectMutation();
  const project = useGetProjectSelector();

  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (projectID) {
      getProject(projectID);
    }
  }, [projectID]);

  // const groupLines =
  //   groupID && project
  //     ? project.groups.find((group) => Number(group.id) === Number(groupID))
  //     : [];

  useEffect(() => {
    if (groupID && project && !groupLines.length) {
      setGroupLines(
        project.groups.find((group) => Number(group.id) === Number(groupID)).lines,
      );
    }
  }, [contentType, groupID, project]);

  return (
    <>
      {isLoading && <MapLoader />}
      <div className={styles['media-page-container']}>
        <div className={styles['content-wrapper']}>
          <HeaderMediaWrapper isOpened={isOpened} setIsOpened={setIsOpened} />
          <div className={styles['media-page-content']}>
            <Suspense fallback={<Spinner />}>
              <MediaView {...props} />
            </Suspense>
          </div>
          <div
            className={
              isOpened
                ? styles['media-page-sidebar-active']
                : styles['media-page-sidebar']
            }
          >
            {!isTabletLandscape && (
              <SidebarZones
                lineContent={
                  groupLines.length
                    ? groupLines
                    : project && project.lines
                    ? project.lines
                    : []
                }
              />
            )}
          </div>
        </div>
      </div>
      {isLoading && <MapLoader />}
    </>
  );
};
