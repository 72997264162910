import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import styles from './map-page.module.scss';
import {
  clearProject,
  useAppDispatch,
  useEditFieldSelector,
  useGetBaseObjectsMutation,
  useGetProjectMutation,
  useGetObjectsMutation,
  useGetUserInfoMutation,
  useObjectMonitoringProjectIdSelector,
} from '../../state';
import {
  AnalyticsViewWrapper,
  CardView,
  EditorView,
  Footer,
  MapZoomButtons,
  ObjectCreationView,
} from '../../components';
import SidebarFilterWrapper from '../../components/sidebar-filter/sidebar-filter-wrapper';
import { SidebarWrapper } from '../../components/sidebar/sidebar-wrapper';
import { useAppSelector } from '../../state/selector';
import { HeaderMapWrapper } from '../../components/header/header-map/header-map-wrapper';
import { CoordsPanel } from '../../components/coords-panel/coords-panel';
import { ImageGalleryMap } from '../../components/image-gallery-map';
import { MapPageTypes } from './map-page.types';
import {
  HistoryCreateObject,
  HistorySelectedFilters,
} from '../../components/history-components';
import { MapLoader } from '../../components/common';
import { useViewModeSelector } from '../../history-state/selectors';

const MapView = lazy(() =>
  import('../../components/view/map-view').then((module) => ({
    default: module.MapView,
  })),
);

export const MapPage = ({ children }: MapPageTypes): JSX.Element => {
  const [, { isLoading }] = useGetBaseObjectsMutation();
  const [getUserInfo] = useGetUserInfoMutation();
  const { user } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const editField = useEditFieldSelector();
  const params = useParams();
  const [getProject] = useGetProjectMutation();
  const monitoringProjectId = useObjectMonitoringProjectIdSelector(params.objectID);
  // const [getLines] = getAllLinesByProject
  const [viewMode] = useViewModeSelector();
  const [getObjectsMutate] = useGetObjectsMutation();

  useEffect(() => {
    if (monitoringProjectId) {
      getProject(monitoringProjectId);
    } else {
      dispatch(clearProject());
    }
  }, [monitoringProjectId]);

  useEffect(() => {
    // !items.length && getObjects();
    // !types.length && getObjectsTypes();
    // !favoriteItems.length && getFavorites();
    !user.profile && getUserInfo();
    getObjectsMutate();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles['map-wrapper']} map-container`}>
          {viewMode === 'map' && <HeaderMapWrapper isLoading={isLoading} />}
          {children}

          <Suspense fallback={<MapLoader />}>
            <MapView />
          </Suspense>

          {viewMode === 'map' && <MapZoomButtons />}
          <Footer />
          <CardView isLoading={isLoading} />
          {editField && <EditorView label={'Сохранить изменения'} />}
          <ObjectCreationView />

          <AnalyticsViewWrapper />
          <CoordsPanel />
          <ImageGalleryMap />
          <HistorySelectedFilters />
          <HistoryCreateObject />
        </div>
        <SidebarFilterWrapper />
        <Routes>
          <Route path={'*'} element={<SidebarWrapper />}>
            <Route path={'group/:groupID'} element={<SidebarWrapper />} />
          </Route>
        </Routes>
      </div>
    </>
  );
};
