import { useSearchParamsAsObject } from '../hooks';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { useDeviceSelector } from '../state';
import { Layers as LayersList, Map } from '../constants';
import { LayerType } from 'types';

export const useSidebarState = () => {
  const { isMobile } = useDeviceSelector();
  const [{ isSidebarOpen = '1', ...rest }, setSearchParams] = useSearchParamsAsObject();
  // const [{ isSidebarOpen = String(Number(!isMobile)), ...rest }, setSearchParams] = useSearchParamsAsObject();

  const handleChangeSidebarState = useCallback(
    (value: boolean) => {
      setSearchParams({ ...rest, isSidebarOpen: value ? '1' : '0' });
    },
    [rest, setSearchParams],
  );

  return [isSidebarOpen === '1', handleChangeSidebarState] as const;
};

export const useViewModeSelector = (): ['map' | 'list' | 'table', any] => {
  const [{ viewMode = 'map', ...rest }, setSearchParams] = useSearchParamsAsObject();

  const selectViewMode = useCallback((viewMode: string) => {
    switch (viewMode) {
      case 'list':
        return 'list';
      case 'table':
        return 'table';
      case 'map':
      default:
        return 'map';
    }
  }, []);

  const handleChangeViewModeState = useCallback(
    (value: string) => {
      setSearchParams({ viewMode: selectViewMode(value), ...rest });
    },
    [rest, setSearchParams],
  );

  return [selectViewMode(viewMode), handleChangeViewModeState];
};

export const useViewLayerState = (): [LayerType, any] => {
  const [{ layer = LayersList.D, ...rest }, setSearchParams] = useSearchParamsAsObject();

  const selectLayer = useCallback((layer: LayerType) => layer, []);

  const handleChangeViewModeState = useCallback(
    (value: LayerType) => {
      setSearchParams({ layer: value, ...rest });
    },
    [rest, setSearchParams],
  );

  return [selectLayer(layer as LayerType), handleChangeViewModeState];
};

export const useIsGazpromChildState = () => {
  const [{ gazprom }] = useSearchParamsAsObject();

  return typeof gazprom !== 'undefined';
};

export const useLatLngZoomSelector = () => {
  const [
    { lat = Map.DEFAULT_LAT, lng = Map.DEFAULT_LNG, zoom = Map.MIN_ZOOM, ...rest },
    setSearchParams,
  ] = useSearchParamsAsObject();

  const handleChangeViewModeState = useCallback(
    (lat: string, lng: string, zoom: string) => {
      setSearchParams({ lat, lng, zoom, ...rest });
    },
    [rest, setSearchParams],
  );

  return [{ lat, lng, zoom }, handleChangeViewModeState] as const;
};

export const useSelectedObjectSelector = (): number | null => {
  const { objectID } = useParams();

  return objectID ? Number(objectID) : null;
  // return useAppSelector(({ objects }) => objects.selectedItem);
};
