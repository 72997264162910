import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MiniCarousel } from '../mini-carousel';
import styles from './photos-switcher.module.scss';
import { Button, MiniCarouselControls, SpanTag } from '../common';
import { PhotosSwitcherProps } from './photos-switcher.types';
import { useAddObjectImageMutation } from '../../state';

export const PhotosSwitcher = ({
  editorMode = false,
  photoList = [],
  onImageClick,
  type = 'sidebar',
  galleryType,
  photosSwitcherTitle,
}: PhotosSwitcherProps) => {
  const imageListRef = useRef<HTMLUListElement | null>(null);
  const [addObjectImage, { isError }] = useAddObjectImageMutation();
  const { objectID } = useParams();
  const imageListItemRef = useRef<HTMLLIElement | null>(null);
  const [isPreviousButtonDisabled, setPreviousButtonDisabled] = useState<boolean>(() => {
    return true;
  });
  const [isNextButtonDisabled, setNextButtonDisabled] = useState<boolean>(() => {
    if (photoList && photoList.length) return false;
    return true;
  });

  const isControlsAvailable =
    imageListRef &&
    imageListRef.current &&
    imageListRef?.current?.scrollWidth < photoList.length * 186 + photoList.length * 8;

  const addPhotoHandlerC = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e?.target;
    if (field.value) {
      const img = field && field?.files !== null && field?.files[0];
      img && addObjectImage({ image: img, id: Number(objectID) });
    }
  };

  useEffect(() => {
    return () => {
      imageListRef !== null &&
        imageListRef.current &&
        imageListRef?.current.scroll({
          left: 0,
          top: 0,
        });
      setPreviousButtonDisabled(() => true);
      setNextButtonDisabled(() => false);
    };
  }, [photoList]);

  const getButtonsState = () => {
    let prevButtonDis = false;
    let nextButtonDis = false;
    if (imageListRef.current) {
      const ulListScrollLeft = imageListRef.current?.scrollLeft;
      const ulListOffsetWidth = imageListRef.current?.offsetWidth;
      const ulListScrollWidth = imageListRef.current?.scrollWidth;

      prevButtonDis = ulListScrollLeft <= 0;

      nextButtonDis = ulListOffsetWidth + ulListScrollLeft + 1 >= ulListScrollWidth;

      return [prevButtonDis, nextButtonDis];
    }
    return [prevButtonDis, nextButtonDis];
  };

  const getNextButtonScrollWidth = () => {
    if (imageListRef.current) {
      const element = imageListItemRef.current;
      const computedStyles = element && getComputedStyle(element);

      if (element && computedStyles) {
        return (
          imageListRef.current.scrollLeft +
          element.clientWidth +
          parseInt(computedStyles.marginRight)
        );
      }
      return null;
    }
    return null;
  };

  const getPrevButtonScrollWidth = () => {
    if (imageListRef.current) {
      const element = imageListItemRef.current;
      const imageListComputedStyles = getComputedStyle(imageListRef.current);
      if (element) {
        return -element.clientWidth - parseInt(imageListComputedStyles.marginLeft);
      }
      return null;
    }
    return null;
  };

  const nextImage = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (imageListRef.current) {
      const width = getNextButtonScrollWidth();
      width &&
        imageListRef.current.scroll({
          left: width,
          top: 0,
          behavior: 'smooth',
        });
      const [prev, next] = getButtonsState();
      setPreviousButtonDisabled(prev);
      setNextButtonDisabled(next);
    }
  };

  const prevImage = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (imageListRef.current) {
      const width = getPrevButtonScrollWidth();

      width &&
        imageListRef.current?.scrollBy({
          left: width,
          top: 0,
          behavior: 'smooth',
        });
      const [prev, next] = getButtonsState();
      setPreviousButtonDisabled(prev);
      setNextButtonDisabled(next);
    }
  };

  const handleCarouselScroll = () => {
    const [prev, next] = getButtonsState();
    setPreviousButtonDisabled(prev);
    setNextButtonDisabled(next);
  };
  if (!photoList.length && !editorMode) {
    return <></>;
  }

  return (
    <div className={styles['photos-switcher-container']}>
      {photosSwitcherTitle && (
        <div className={styles['photos-switcher-container__title']}>
          <SpanTag weight={1}>{photosSwitcherTitle}:</SpanTag>
        </div>
      )}
      {photoList.length > 0 ? (
        <MiniCarousel
          galleryType={galleryType}
          onCarouselScroll={handleCarouselScroll}
          type={type}
          editorMode={editorMode}
          photoList={photoList}
          imageListRef={imageListRef}
          onImageClick={onImageClick}
          imageListItemRef={imageListItemRef}
        />
      ) : null}
      <div className={styles['photos-switcher-controls-wrapper']}>
        {type === 'card' && photoList.length > 1 ? (
          <MiniCarouselControls
            photoList={photoList}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            isNextButtonDisabled={isNextButtonDisabled}
            nextImage={nextImage}
            prevImage={prevImage}
          />
        ) : (
          photoList.length > 3 &&
          isControlsAvailable && (
            <MiniCarouselControls
              photoList={photoList}
              isPreviousButtonDisabled={isPreviousButtonDisabled}
              isNextButtonDisabled={isNextButtonDisabled}
              nextImage={nextImage}
              prevImage={prevImage}
            />
          )
        )}
        {editorMode && photoList.length ? (
          <Button
            className={styles['photoswitcher-edit-button']}
            size={type === 'sidebar' ? 's' : 'l'}
            elementType={'button'}
            typeBtn={'secondary'}
            text={'Редактировать'}
            showLeftIcon={true}
            leftIcon={<i className="icon icon-edit-2 icon-middle" />}
            onClick={() => onImageClick(photoList[0])}
          />
        ) : null}
        {editorMode && !photoList.length ? (
          <Button
            className={styles['photoswitcher-add-photo-button']}
            elementType={'input'}
            typeBtn={'secondary'}
            text={'Добавить фото объекта'}
            size={type === 'sidebar' ? 's' : 'l'}
            name="img"
            accept="image/png, image/jpg, image/jpeg"
            showLeftIcon={true}
            leftIcon={<i className="icon icon-edit-2 icon-middle" />}
            onChange={addPhotoHandlerC}
          />
        ) : null}
      </div>
    </div>
  );
};
