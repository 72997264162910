import { GeoJson } from '../geo-json';
import { GeoJsonObject } from 'geojson';
import { useIsGazpromFilteredActive } from '../../../../state/selector';
import russia from '../api/russia.json';
import L from 'leaflet';
import { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { Layers as LayersTypes } from '../../../../constants';
import { DistrictsLayer } from './districts-layer';
import { RegionsLayer } from './regions-layer';
import { Layers as LayersList } from '../../../../constants';
import { Map as MapConstants } from '../../../../constants';
import { useViewLayerState } from 'history-state';
import { ContentWrapper } from '../content';

export const Layers = () => {
  const [selectedLayer, setLayer] = useViewLayerState();
  const map = useMap();
  const isGazpromChildActive = useIsGazpromFilteredActive();

  const flyToDistrict = useCallback((coords: [number, number]) => {
    setLayer(LayersList.R);
  }, []);

  const flyToRegion = useCallback((coords: [number, number]) => {
    setLayer(LayersList.O);
  }, []);

  useEffect(() => {
    if (selectedLayer !== LayersTypes.O) {
      map.getContainer().style.setProperty('filter', 'brightness(90%)');
    } else {
      map.getContainer().style.setProperty('filter', 'brightness(100%)');
    }
  }, [selectedLayer]);

  return (
    <>
      <GeoJson
        className={'map-overlay'}
        data={russia as GeoJsonObject}
        invert={true}
        color={'#D82600'}
        stroke={false}
        fillColor={'#CFCFCF'}
        fillOpacity={0.7}
        weight={6}
        interactive={false}
        worldLatLngs={[
          L.latLng([90, 360]),
          L.latLng([90, -180]),
          L.latLng([-90, -180]),
          L.latLng([-90, 360]),
        ]}
        coordsToLatLng={(coords) => {
          if (coords[0] > -160) {
            return L.latLng(coords[1], coords[0]);
          } else {
            return L.latLng(coords[1], coords[0] + 360);
          }
        }}
      />

      {selectedLayer === LayersTypes.D && (
        <DistrictsLayer
          isGazpromChildActive={isGazpromChildActive}
          flyToDistrict={flyToDistrict}
        />
      )}
      {selectedLayer === LayersTypes.R && (
        <RegionsLayer
          isGazpromChildActive={isGazpromChildActive}
          isShortTitle={map.getZoom() < MapConstants.FULL_REGION_TITILE_MAX_ZOOM_LEVEL}
          flyToRegion={flyToRegion}
        />
      )}
      {selectedLayer === LayersTypes.O && <ContentWrapper />}
    </>
  );
};
