/* eslint-disable react-hooks/rules-of-hooks */
import { ObjectTransgazInterface } from '../types/object';

export const filterObjects = (filters: any, items: any) => {
  let result = [...items];

  if (filters.national_projects.length) {
    result = result.filter((el: any) => {
      return filters.national_projects.includes(Number(el.national_project_id));
    });
  }
  if (filters.directions.length) {
    result = result.filter((el: any) => {
      return filters.directions.includes(Number(el.direction_id));
    });
  }
  if (filters.status.length) {
    result = result.filter((el: any) => {
      return filters.status.includes(Number(el.realization_status));
    });
  }

  if (filters.gazprom.length) {
    result = result.filter((el: any) => {
      return filters.gazprom.includes(el.gazprom_filter);
      // return filters.gazprom.includes(Number(el.gazprom_filter));
    });
  }

  return result;
};

const getAverage = (numbers: number[]) => {
  let sum = 0;
  for (let i = 0; i < numbers.length; i += 1) {
    sum += numbers[i];
  }
  return sum / numbers.length;
};

export const getActiveRegionIdsListFromObjects = (items: any[]) => {
  // const items = useFilteredProtoItems()
  const regionsSet = new Set();
  items.forEach((item: any) => {
    regionsSet.add(item.region);
  });

  return Array.from(regionsSet) as number[];
};

export const getActiveDistrinctRegionsIdsListFromObjects = (items: any[]) => {
  // const items = useDistrictFilteredObjects()
  const regionsSet = new Set();
  items.forEach((item: any) => {
    regionsSet.add(item.region);
  });
  return Array.from(regionsSet) as number[];
};

export const getColor = (
  items: ObjectTransgazInterface[],
  placeId: number | number[],
) => {
  const ratingList = items
    .map((item: any) => {
      if (item.region === placeId) {
        return item.rating;
      }
    })
    .filter((el) => typeof el === 'number');

  if (!ratingList.length) return null;

  return getAverage(ratingList);
};

export const getRegionRating = (items: ObjectTransgazInterface[], regionId: number) => {
  // const items = useFilteredProtoItems()
  const objects = items.filter((item) => {
    return item.region === regionId;
  });

  const ratingList = objects.map((item) => item.rating);

  return getAverage(ratingList);
};

export const getDistrictRegionRating = (
  items: ObjectTransgazInterface[],
  regionId: number,
) => {
  // const items = staticItems
  const objects = items.filter((item) => {
    return item.region === regionId;
  });

  const ratingList = objects.map((item) => item.rating);

  return getAverage(ratingList);
};

export const isDistrictRatingFilterActive = (array1: number[], array2: number[]) => {
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i] === array2[j]) {
        return true;
      }
    }
  }
  return false;
};

export const isDistrictActive = (array1: number[], array2: number[]) => {
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i] === array2[j]) {
        return true;
      }
    }
  }
  return false;
};

export const getDistrictRating = (
  items: ObjectTransgazInterface[],
  regionsList: number[],
) => {
  let preliminaryList: number[] = [];

  regionsList.forEach((item) => {
    preliminaryList = [...preliminaryList, getRegionRating(items, item)];
  });

  const filteredList = preliminaryList.filter((item) => !isNaN(item));

  return getAverage(filteredList);
};

// pam pam

export const getDistrictRegionsMacroRating = (items: any[], regionId: number) => {
  // const items = useDistrictFilteredObjects()
  const objects = items.filter((item) => {
    return item.region === regionId;
  });

  const ratingList = objects.map((item) => item.rating);

  return getAverage(ratingList);
};

export const getDistrictMacroRating = (items: any[], regionsList: number[]) => {
  let preliminaryList: number[] = [];

  regionsList.forEach((item) => {
    preliminaryList = [...preliminaryList, getDistrictRegionsMacroRating(items, item)];
  });

  const filteredList = preliminaryList.filter((item) => !isNaN(item));

  return getAverage(filteredList);
};
