/* eslint-disable prefer-const */
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { ImageGalleryWrapper } from '../image-gallery-wrapper';
import {
  setGalleryObjectId,
  toggleSidebar,
  useEditorModeStateSelector,
  useGalleryTypeSelector,
} from '../../state';
import { Button, ToolTip } from '../common';
import { useGalleryObjectIdSelector } from '../../state/selector';
import { useAppObjects } from '../../state/selector/selector';
import { useDispatch } from 'react-redux';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import styles from './image-gallery-map.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAddObjectImageMutation, useDeleteObjectImageMutation } from '../../state/';
import { ServerErrorModal } from '../server-error-modal/server-error-modal';
import { ImageWrapper } from 'components/image-wrapper';
import thumbImg from 'images/object-bg-image.png';

export const ImageGalleryMap = () => {
  const isEditMode = useEditorModeStateSelector();
  const objects = useAppObjects();
  const galleryImg = useGalleryObjectIdSelector();
  const galleryType = useGalleryTypeSelector();

  const galleryImgId = galleryImg ? galleryImg.id : null;
  const dispatch = useDispatch();
  const [objId, setObjId] = useState<number>();
  const [indexListen, setIndexListen] = useState(0);
  const [showGalleryMap, setShowGalleryMap] = useState<boolean>(false);
  const obj = objects
    ? objects.find((item) => item.id === Number(galleryImg?.object_id))
    : false;
  const navigate = useNavigate();
  let ref = useRef<ImageGallery>(null);
  const [addObjectImage, { isError }] = useAddObjectImageMutation();
  const [deleteObjectImage] = useDeleteObjectImageMutation();

  const media = !!obj && !!obj.media && obj.media;
  const gratitude = !!obj && !!obj.gratitude && obj.gratitude;

  const [openModalError, setOpenModalError] = useState<boolean>(false);

  useEffect(() => {
    setOpenModalError(isError);
  }, [isError]);

  const galleryMedia: any = useMemo(() => {
    if (media && galleryType === 'object') {
      return media.map((item) => {
        return {
          originalAlt: `${obj.name} фото`,
          // originalClass: 'd-block w-75 mx-auto overflow-hidden',
          original: item.path,
          thumbnail: item.path_thumbnail ? item.path_thumbnail : item.path,
          id: item.id,
        };
      });
    }

    if (gratitude && galleryType === 'gratitude') {
      return gratitude.map((item) => {
        return {
          originalAlt: `${obj.name} фото`,
          // originalClass: 'd-block w-75 mx-auto overflow-hidden',
          original: item.path,
          thumbnail: item.path_thumbnail ? item.path_thumbnail : item.path,
          id: item.id,
        };
      });
    }
  }, [media, obj, gratitude, galleryType]);

  useEffect(() => {
    if (galleryImgId && ref.current && galleryMedia) {
      const findIndex = galleryMedia.findIndex((el: any) => el.id === galleryImgId);
      if (findIndex !== null && findIndex !== undefined) {
        ref.current.slideToIndex(findIndex);
      }
    }
  }, [galleryImgId, galleryMedia]);

  useEffect(() => {
    setOpenModalError(openModalError);
  }, [openModalError]);

  useEffect(() => {
    if (obj) {
      setObjId(obj.id);
    }
  }, [obj, objId]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e?.target;
    if (field.value) {
      const img = field && field?.files !== null && field?.files[0];

      img && addObjectImage({ image: img, id: Number(objId) });
    }
  };

  const handleDelete = () => {
    try {
      galleryMedia &&
        galleryMedia.findIndex((item: { id: number }, index: number) =>
          index === indexListen ? deleteObjectImage({ id: Number(item.id) }) : false,
        );
    } catch (err: any) {
      throw new Error('Error occured ', err.toString());
    }
  };

  const tollText = (
    <div className={styles['gallery-popup-message']}>
      <div className={styles['gallery-popup-message-body']}>
        Допустимый формат фотографии <b>png, jpg, jpeg</b>. Размер файла не должен
        превышать <b>15МБ</b>.
      </div>
    </div>
  );

  const handleSlide = (idx: number) => {
    setIndexListen(idx);
  };

  return !!obj ? (
    <div className={styles['gallery-panel']}>
      {openModalError && (
        <ServerErrorModal isError={isError} onClose={() => setOpenModalError(false)} />
      )}
      <div className={styles['gallery-panel_btn-box']}>
        {/*<PageBlock isShowBlock={imagePart}/>*/}
        {isEditMode && (
          <>
            <form>
              <label
                htmlFor="file-upload"
                className={styles['gallery-panel_btn-box_add-btn']}
              >
                <ToolTip isVisible content={tollText}>
                  Добавить фото
                  <input
                    onChange={handleChange}
                    id="file-upload"
                    type="file"
                    name="img"
                    value=""
                    accept="image/png, image/jpg, image/jpeg"
                  />
                </ToolTip>
              </label>
            </form>
            <button
              onClick={handleDelete}
              className={styles['gallery-panel_btn-box_delete-btn']}
            >
              Удалить фото
            </button>
          </>
        )}
        <Button
          style={{ paddingTop: 3 }}
          elementType={'button'}
          onlyIcon={true}
          typeBtn={'uncolored'}
          className={'ms-auto me-0'}
          onClick={() => dispatch(setGalleryObjectId(null))}
          showLeftIcon
          leftIcon={<i className={'icon icon-close-3 icon-middle'} />}
        />
      </div>
      {!!obj && (
        <ImageGalleryWrapper
          galleryMedia={galleryMedia as ReactImageGalleryItem[]}
          showThumbnails={true}
          showPlayButton={false}
          showFullscreenButton={false}
          lazyLoad={true}
          galleryRef={ref}
          showBullets={false}
          useBrowserFullscreen={false}
          onSlide={handleSlide}
          onScreenChange={() => dispatch(toggleSidebar())}
          disableThumbnailScroll={false}
          fullScreenModeOnClick={true}
          renderThumbInner={(item: {
            originalAlt?: string;
            original?: string;
            thumbnail?: string;
            id?: number;
          }) => (
            <ImageWrapper
              id={String(item?.id)}
              src={String(item.thumbnail)}
              alt={String(item?.originalAlt)}
              thumb={thumbImg}
            />
          )}
        />
      )}
    </div>
  ) : null;
};
