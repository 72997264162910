import { FC } from 'react';
import './content.style.scss';
import type { ContentWrapperProps } from './content.types';
import { useFilteredObjectsSelector, useObjectsTypesSelector } from '../../../../state';
import { ClusterContent } from './cluster-content';
import { ObjectInterface } from '../../../../types';
import { useIsGazpromChildState } from 'history-state/selectors';
import { GazpromClusterContent } from './gazprom-cluster-content';

export const ContentWrapper: FC<ContentWrapperProps> = () => {
  const items = useFilteredObjectsSelector();
  const types = useObjectsTypesSelector();
  const isGazpromChildActive = useIsGazpromChildState();

  const test = items.find((el) => el.id === 39);
  // const test = items.find((el) => el.id === 39);

  console.log(JSON.stringify(test));

  if (isGazpromChildActive) {
    return (
      <GazpromClusterContent
        items={items as unknown as ObjectInterface[]}
        types={types}
      />
    );
  }

  return <ClusterContent items={items as unknown as ObjectInterface[]} types={types} />;
};
