export const districts = [
  {
    data: null,
    name: 'Центральный ФО',
    id: 1,
    regions: [8, 9, 73, 76, 23, 27, 83, 32, 34, 39, 38, 47, 53, 61, 64, 68, 67, 78],
    rating: null,
  },
  {
    data: null,
    name: 'Дальневосточный ФО',
    id: 2,
    regions: [4, 10, 17, 7, 11, 35, 51, 57, 56, 20, 15],
    coords: [66.77025292928913, 132.83593750000003],
    rating: null,
  },
  {
    data: null,
    name: 'Северо-Западный ФО',
    id: 3,
    regions: [5, 74, 25, 29, 82, 33, 40, 41, 42, 52, 50],
    rating: null,
  },
  {
    data: null,
    name: 'Сибирский ФО',
    id: 4,
    regions: [2, 3, 22, 30, 85, 43, 45, 66, 69, 18],
    coords: [66.77025292928913, 95.61811523437501],
    rating: null,
  },
  {
    data: null,
    name: 'Уральский ФО',
    id: 5,
    regions: [31, 63, 70, 19, 13, 77],
    rating: null,
  },
  {
    data: null,
    name: 'Приволжский ФО',
    id: 6,
    coords: [55.77025292928913, 50.83593750000003],
    regions: [80, 81, 36, 37, 46, 48, 49, 58, 59, 65, 71, 72, 14, 44],
    rating: null,
  },
  {
    data: null,
    name: 'Южный ФО',
    id: 7,
    regions: [1, 6, 75, 26, 84, 54, 79, 60],
    rating: null,
  },
  {
    data: null,
    name: 'Северо-Кавказский ФО',
    id: 8,
    regions: [16, 21, 24, 28, 55, 62, 12],
    rating: null,
  },
];
